<template>
  <div id="test">
    <LackOfInventory />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>
<script>
import LackOfInventory from "@/components/Modals/LackOfInventory";

export default {
  data() {
    return {
      user: {
        fullname: "مرجان سادات خلیل پور",
        mobile: "۳۸۴۷۸۴۸۷۴",
        national_code: "۳۸۴۷۸۴۸۷۴",
        email: "blaablaa@gmail.com",
        city: "تهران",
        province: "تهران"
      },
      transaction: {
        max_daily_transaction: 4000000,
        sum_today_transaction: 3000000,
        amount_allowed_today: 1000000
      },
      crumbs: [
        "خانه",
        "بیت کویین",
        "هاوینگ یا نصف شدن پاداش استخراج بیت کوین‌‌ (Halving) چیست؟"
      ]
    };
  },
  components: {
    LackOfInventory
  },
  methods: {
    // selected(crumb) {
    //   console.log(crumb);
    // }
  }
};
</script>
<style scoped>
#test {
  height: 100vh;
  width: 100%;
  /*padding: 0 70px;*/
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 0 10px;
}

/*#test {*/
/*  height: 100vh;*/
/*  display: flex;*/
/*  padding: 0 10px;*/
/*  height: 100vh;*/
/*  display: flex;*/
/*  padding: 0 10px;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*  width: 475px;*/
/*}*/

.test {
  /* margin: auto; */
  /* margin-top: 10%; */
}

@media (min-width: 960px) {
  #test {
    padding: 0 70px;
  }
}
</style>
